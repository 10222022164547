@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-yellow-400
}

@layer base {
    .main-container {
        @apply container mx-auto px-4 sm:px-6 flex h-screen flex-col;
    }
    .header {
        @apply relative pb-8;
    }
    .header .header-container {
        @apply max-w-7xl mx-auto;
    }
    .header .header-container .header-nav {
        @apply flex justify-center items-center border-b border-green-600 py-6 md:space-x-10;
    }
    .game-grid {
        @apply grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4;
    }
    .game-grid .game-item {
        @apply h-auto rounded-md flex;
    }
    .game-grid .game-item a {
        @apply h-full w-full text-center
    }
    .game-grid .game-item a img {
        @apply rounded-md inline-block shadow-xl;
    }
    .game-container {
        @apply flex h-screen;
    }
    .game-container iframe {
        @apply h-full w-full border-0 overflow-hidden;
    }
    .footer {
        @apply relative pt-8;
    }
    .footer .footer-container {
        @apply w-full mx-auto text-sm;
    }
    .footer .footer-container .footer-nav {
        @apply grid grid-flow-row grid-cols-4 border-t border-green-600 pt-4 pb-4 md:justify-start md:gap-x-10;
    }
}
